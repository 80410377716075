<template>
  <div>
    <beta-banner />
  </div>
</template>

<script>
import BetaBanner from './BetaBanner.vue';

export default {
  name: 'BetaIntroduction',
  components: { BetaBanner },
};
</script>
