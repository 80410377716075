import SchemeService from '../services/scheme-service';

export const scheme = {
  namespaced: true,
  state: {
    scheme: null,
  },
  actions: {
    getScheme({ commit }) {
      commit('setIsBusy', true, { root: true });
      return SchemeService.get().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setScheme', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateRestrictRIAPIProjectTypes({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeService.updateRestrictRIAPIProjectTypes(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setScheme', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setScheme(state, newScheme) {
      if (!newScheme.restrictRIAPIProjectTypes) {
        newScheme.restrictRIAPIProjectTypes = [];
      }
      state.scheme = newScheme;
    },
  },
};
