import { HTTP, authHeader } from './http-service';

class ExportService {
  getAll() {
    return HTTP.get(`/retrofitexport/getall`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  get(id) {
    return HTTP.get(`/retrofitexport/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(id) {
    return HTTP.delete(`/retrofitexport/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  deletePending(id) {
    return HTTP.delete(`/retrofitexport/pending/${id}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  download(id) {
    return HTTP.get(`/retrofitexport/download/csv/${id}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  post(payload) {
    return HTTP.post(`/retrofitexport`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new ExportService();
