<template>
  <span ref="el">
    <slot />
    <font-awesome-icon
      v-if="!hideinfo"
      icon="circle-question"
      class="icon float-end mt-2"
      style="color: #71217b"
    />
  </span>
</template>

<script>
import { Popover as BootstrapPopover } from 'bootstrap';

export default {
  name: 'Popover',
  props: ['content', 'placement', 'trigger', 'hideinfo'],
  data() {
    return {
      el: null,
    };
  },
  mounted() {
    if (this.content) {
      this.el = new BootstrapPopover(this.$refs.el, {
        container: 'body',
        html: true,
        trigger: this.trigger ?? 'hover click focus',
        placement: this.placement ?? 'bottom',
        content: this.content ?? ' ',
      });
    }
  },
  unmounted() {
    if (this.el) {
      this.el.dispose();
    }
  },
};
</script>

<style scoped>
.icon {
  font-size: 80%;
}
</style>
