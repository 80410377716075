<template>
  <nav
    id="collapseExample"
    class="fixed-bottom collapse bg-transparent h-50 min-vh-25"
    style="overflow-y: scroll"
  >
    <div class="row">
      <div class="col-md-3 col-lg-2"></div>
      <div class="col-md-9 col-lg-10">
        <div class="container-fluid d-inline-block bg-white min-vh-100">
          <hr />

          <div class="btn-toolbar mb-2 mb-md-0 float-end sticky-top">
            <div class="btn-group me-2">
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
              >
                Close
              </button>
            </div>
          </div>
          <div>
            <activity-list :model="model" />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ActivityList } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'ActivityLogContainer',
  components: {
    ActivityList,
  },
  computed: {
    model() {
      return this.$store.state.activityLog.activityLogModel;
    },
  },
};
</script>
