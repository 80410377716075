import WebhookService from '../services/webhook-service';

export const webhook = {
  namespaced: true,
  state: {
    webhook: null,
  },
  actions: {
    getWebhook({ commit }) {
      commit('setIsBusy', true, { root: true });
      return WebhookService.get().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setWebhook', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateWebhook({ commit }, model) {
      commit('setIsBusy', true, { root: true });
      return WebhookService.update(model)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setWebhook(state, newWebhook) {
      state.webhook = newWebhook;
    },
  },
};
