import { HTTP, authHeader } from './http-service';

class RegistrationBillingService {
  list() {
    return HTTP.get(`/schemeregistrationbilling`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(payload) {
    return HTTP.get(`/schemeregistrationbilling/declaration/${payload}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/schemeregistrationbilling/declaration`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new RegistrationBillingService();
