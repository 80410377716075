import { HTTP, authHeader } from './http-service';

class BusinessTradeRegistrationsService {
  create(payload) {
    return HTTP.post(`/businesstrade`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/businesstrade`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  list(schemeBusinessId) {
    return HTTP.get(`/businesstrade/${schemeBusinessId}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(schemeBusinessId, tradeId) {
    return HTTP.get(`/businesstrade/${schemeBusinessId}/${tradeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new BusinessTradeRegistrationsService();
