import ExportService from '../services/export-service';

export const retrofitExports = {
  namespaced: true,
  state: {
    exports: null,
  },
  actions: {
    getAll({ commit }) {
      // commit('setIsBusy', true, { root: true });
      return ExportService.getAll().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setExports', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    get({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      return ExportService.get(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    delete({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      return ExportService.delete(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deletePending({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      return ExportService.deletePending(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    post({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return ExportService.post(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    download({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      return ExportService.download(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setExports(state, searchResult) {
      state.exports = searchResult;
    },
  },
};
