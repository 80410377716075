import BusinessContactService from '../services/business-contact-service';

export const businessContact = {
  namespaced: true,
  state: {
    contacts: null,
    contact: null,
  },
  actions: {
    newContact({ rootState, commit }) {
      const emptyContact = {
        schemeBusinessId: null,
        schemeId: rootState.auth.account.scheme.schemeId,
        firstName: '',
        lastName: '',
      };
      commit('setContact', emptyContact);
    },
    listContacts({ commit }, schemeBusinessId) {
      return BusinessContactService.list(schemeBusinessId)
        .then((result) => {
          commit('setContacts', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    createContact({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessContactService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getContact({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setContact', null);
      return BusinessContactService.get(payload.schemeBusinessId, payload.contactId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setContact', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateContact({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessContactService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deleteContact({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return BusinessContactService.delete(payload.schemeBusinessId, payload.contactId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setContacts(state, newContacts) {
      state.contacts = newContacts;
    },
    setContact(state, newContact) {
      state.contact = newContact;
    },
  },
};
