import PasswordResetService from '../services/password-reset-service';

export const passwordReset = {
  namespaced: true,
  state: {},
  actions: {
    resetWithCode({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return PasswordResetService.resetWithCode(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    sendCode({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return PasswordResetService.sendCode(payload.username).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {},
};
