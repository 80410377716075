import RegistrationBillingService from '../services/registration-billing-service';

export const registrationBilling = {
  namespaced: true,
  state: {
    monthRegistrations: null,
    declaration: null,
  },
  actions: {
    newDeclaration({ rootState, commit }) {
      const declarationTemplate = JSON.parse(
        JSON.stringify(rootState.auth.appData.models.schemeRegistrationBillingDto)
      );
      declarationTemplate.isDeclaration = true;
      declarationTemplate.schemeId = rootState.auth.account.scheme.schemeId;
      declarationTemplate.month = new Date().getMonth() + 1;
      declarationTemplate.year = new Date().getFullYear();
      commit('setDeclaration', declarationTemplate);
    },
    listMonthRegistrations({ commit }) {
      commit('setIsBusy', true, { root: true });
      return RegistrationBillingService.list()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setMonthRegistrations', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getDeclaration({ commit }, payload) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setDeclaration', null);
      return RegistrationBillingService.get(payload.period).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setDeclaration', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    updateDeclaration({ commit }, data) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RegistrationBillingService.update(data)
        .then((result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setDeclaration(state, newDeclaration) {
      state.declaration = newDeclaration;
    },
    setMonthRegistrations(state, newMonthRegistrations) {
      state.monthRegistrations = newMonthRegistrations;
    },
  },
};
