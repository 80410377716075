import { HTTP, authHeader } from './http-service';

class SchemeService {
  get() {
    return HTTP.get(`/scheme`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  getPlatformStatistics(payload) {
    return HTTP.post(`/schemestatistics`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  updateRestrictRIAPIProjectTypes(payload) {
    return HTTP.post(`/scheme/RestrictRIAPIProjectTypes`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new SchemeService();
