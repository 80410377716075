const helpers = {
  capFirstLetter(value) {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  dateTimeString(value, emptyReturn) {
    if (!emptyReturn) emptyReturn = '';
    if (!value) return emptyReturn;
    return `${new Date(value).toLocaleDateString('en-GB')} ${new Date(value).toLocaleTimeString()}`;
  },
  dateString(value, emptyReturn) {
    if (!emptyReturn) emptyReturn = '';
    if (!value) return emptyReturn;
    return new Date(value).toLocaleDateString('en-GB');
  },
  dateForApi(value) {
    if (!value) return null;
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}T00:00:00`;
  },
  dateEndOfDayForApi(value) {
    if (!value) return null;
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}T23:59:59`;
  },
  formatDate(date) {
    if (date !== undefined && date !== '' && date !== null) {
      const myDate = new Date(date);
      const month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ][myDate.getMonth()];
      const str = `${myDate.getDate()} ${month} ${myDate.getFullYear()}`;
      return str;
    }
    return null;
  },
};

export default {
  install: (app) => {
    app.config.globalProperties.$helpers = helpers;
  },
};
