import { HTTP, authHeader } from './http-service';

class BusinessContactService {
  list(schemeBusinessId) {
    return HTTP.get(`/businesscontact/${schemeBusinessId}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(schemeBusinessId, contactId) {
    return HTTP.get(`/businesscontact/${schemeBusinessId}/${contactId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/businesscontact`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/businesscontact`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(schemeBusinessId, contactId) {
    return HTTP.delete(`/businesscontact/${schemeBusinessId}/${contactId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new BusinessContactService();
