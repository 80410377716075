<template>
  <div id="beta-banner" class="sticky-top">
    <div class="text-white">
      <div class="container py-2 text-center">
        <font-awesome-icon icon="info" />&nbsp;&nbsp;
        <span>This service is currently in beta</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetaBanner',
};
</script>

<style scoped>
#beta-banner {
  background-color: #343a40;
}
</style>
