import SchemeService from '../services/scheme-service';

export const schemePlatformStatistics = {
  namespaced: true,
  state: {
    statistics: null,
    startDate: null,
  },
  actions: {
    getPlatformStatistics({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setSchemeStatistics', null);
      return SchemeService.getPlatformStatistics(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSchemeStatistics', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setSchemeStatistics(state, newState) {
      state.statistics = newState;
    },
    setStartDate(state, newState) {
      state.startDate = newState;
    },
  },
};
