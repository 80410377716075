import { HTTP, authHeader } from '../services/http-service';

export const activityLog = {
  namespaced: true,
  state: {
    activityLogModel: null,
    formattedActivityLogModel: null,
  },
  actions: {
    getActivityLog({ commit }, url) {
      return HTTP.get(url, {
        headers: authHeader(),
      }).then((response) => {
        commit('setActivityLogModel', response.data.model);
      });
    },
    getFormattedActivityLog({ commit }, url) {
      commit('setIsBusy', true, { root: true });
      return HTTP.get(url, {
        headers: authHeader(),
      })
        .then((response) => {
          commit('setIsBusy', false, { root: true });
          commit('setFormattedActivityLogModel', response.data.model);
        })
        .catch(() => {
          commit('setIsBusy', false, { root: true });
        });
    },
  },
  mutations: {
    setActivityLogModel(state, newActivityLogModel) {
      state.activityLogModel = newActivityLogModel;
    },
    setFormattedActivityLogModel(state, newActivityLogModel) {
      state.formattedActivityLogModel = newActivityLogModel;
    },
  },
};
