<template>
  <scheme-nav-bar v-if="currentAccount" />
  <div class="w-100 overflow-hidden d-flex flex-column flex-lg-row">
    <scheme-sidebar v-if="currentAccount" class="flex-shrink-0" />
    <main class="w-100 overflow-hidden min-vh-100">
      <div class="p-4">
        <router-view />
        <activity-log-container />
      </div>
    </main>
  </div>
</template>

<script>
import SchemeNavBar from './SchemeNavBar.vue';
import SchemeSidebar from './SchemeSidebar.vue';
import ActivityLogContainer from './ActivityLogContainer.vue';

export default {
  name: 'WorkspaceLayout',
  components: {
    SchemeNavBar,
    SchemeSidebar,
    ActivityLogContainer,
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
  },
};
</script>
