import { HTTP } from './http-service';

class PasswordResetService {
  resetWithCode(payload) {
    return HTTP.post(`/ResetPassword/reset-with-code`, payload).then((response) => {
      return response.data;
    });
  }

  sendCode(email) {
    return HTTP.post(`/ResetPassword/sendcode`, { username: email }).then((response) => {
      return response.data;
    });
  }
}

export default new PasswordResetService();
