import DashboardService from '../services/dashboard-service';

export const dashboard = {
  namespaced: true,
  state: {
    dashboard: null,
  },
  actions: {
    getDashboard({ commit, rootState }) {
      if (!rootState.auth.account.roles.includes('SchemeBusinessViewer')) {
        return Promise.resolve('/scheme');
      }
      commit('setIsBusy', true, { root: true });
      return DashboardService.get().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setDashboard', result.model);
          return Promise.resolve('/');
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setDashboard(state, newDashboard) {
      state.dashboard = newDashboard;
    },
  },
};
