import UserService from '../services/user-service';

export const user = {
  namespaced: true,
  state: {
    searchResult: null,
    user: null,
  },
  actions: {
    listUsers({ commit }) {
      return UserService.list().then(
        (result) => {
          const searchResult = {
            results: result.model,
          };
          commit('setUserSearchResult', searchResult);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    newUser({ rootState, commit }) {
      const userTemplate = JSON.parse(JSON.stringify(rootState.auth.appData.models.user));
      commit('setUser', userTemplate);
    },
    getUserDetails({ rootState, commit }, userId) {
      commit('setIsBusy', true, { root: true });
      commit('setUser', null);
      const payload = {
        userId,
        schemeId: rootState.auth.account.scheme.schemeId,
      };
      return UserService.get(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateUser({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return UserService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    invite({ rootState, commit }, data) {
      data.schemeId = rootState.auth.account.scheme.schemeId;
      commit('setIsBusy', true, { root: true });
      return UserService.invite(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    resendInvite({ rootState, commit }, data) {
      data.schemeId = rootState.auth.account.scheme.schemeId;
      commit('setIsBusy', true, { root: true });
      return UserService.resendInvite(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          result.model.resentInvite = true;
          commit('setPartialUserSearchResult', result.model);
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    enableUser({ commit }, email) {
      const data = {
        accountId: email,
        isDisabled: false,
      };
      commit('setIsBusy', true, { root: true });
      return UserService.enable(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    disableUser({ commit }, email) {
      const data = {
        accountId: email,
        isDisabled: true,
      };
      commit('setIsBusy', true, { root: true });
      return UserService.disable(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setUserSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setPartialUserSearchResult(state, newuser) {
      if (state.searchResult) {
        const index = state.searchResult.results.findIndex(
          (x) => x.accountId === newuser.accountId
        );
        if (index > -1) {
          state.searchResult.results[index] = newuser;
        }
      }
    },
    setUser(state, newuser) {
      state.user = newuser;
    },
  },
};
