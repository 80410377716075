import ApiKeyService from '../services/apikey-service';

export const apikey = {
  namespaced: true,
  state: {
    apiKeys: null,
  },
  actions: {
    listApiKeys({ commit }) {
      commit('setIsBusy', true, { root: true });
      return ApiKeyService.list().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setApiKeys', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    createApiKey({ commit }) {
      commit('setIsBusy', true, { root: true });
      return ApiKeyService.create()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deleteApiKey({ commit }, tmapikey) {
      commit('setIsBusy', true, { root: true });
      return ApiKeyService.delete(tmapikey)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setApiKeys(state, searchResult) {
      state.apiKeys = searchResult;
    },
  },
};
