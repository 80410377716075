import AddressSearchService from '../services/address-search-service';

export const addressSearch = {
  namespaced: true,
  state: {
    addresses: null,
  },
  actions: {
    getAddresses({ commit }, postcode) {
      return AddressSearchService.get(postcode)
        .then((result) => {
          commit('setAddresses', result);
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setAddresses(state, newAddresses) {
      state.addresses = newAddresses;
    },
  },
};
