<template>
  <div>
    <div class="row justify-content-center p-3 m-5">
      <div class="col-8 col-md-3">
        <img src="../assets/logo.png" class="img img-fluid" />
      </div>
    </div>
    <div class="container-login">
      <h2 class="pb-3">Register</h2>

      <div v-if="step === 1">
        <form ref="formRegister" class="has-validation" autocomplete="off">
          <fieldset :disabled="loading">
            <div class="mb-5">
              <label
                >You should have already been invited by your scheme administrator and are now
                completing your registration.</label
              >
            </div>

            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
                <router-link v-if="showLoginLink" to="/login">Back to Login</router-link>
              </div>
            </div>

            <div class="form-group">
              <label for="email" class="form-label">Invited Email Address *</label>
              <input
                id="email"
                ref="emailInput"
                v-model="email"
                name="email"
                type="text"
                class="form-control form-control-lg"
                :class="v$.email.$error ? 'is-invalid' : ''"
                autocomplete="off"
              />
              <div v-if="v$.email.$error" class="invalid-feedback">
                {{ v$.email.$errors[0].$message }}
              </div>
            </div>

            <div
              class="form-text d-flex cursor-pointer mt-4 p-4 justify-content-start align-items-center"
              :class="{ selected: usesTotp === true }"
              @click.prevent="usesTotp = true"
            >
              <font-awesome-icon icon="qrcode" class="fs-1" />
              <div class="d-flex flex-column text-start flex-fill ms-4">
                  <b class="fw-bold">Authenticator App</b>
                  <div>
                      Scan a QR code with your authenticator app to register and use the code generated by the app to login.
                  </div>
              </div>
            </div>
            <div
              class="form-text d-flex cursor-pointer p-4 justify-content-start align-items-center"
              :class="{ selected: usesTotp === false }"
              @click.prevent="usesTotp = false"
            >
              <font-awesome-icon icon="mobile" class="fs-1" />
              <div class="d-flex flex-column text-start flex-fill ms-4">
                  <b class="fw-bold">SMS</b>
                  <div>Use your mobile number to receive a code via SMS to login.</div>
              </div>
            </div>
            <div v-if="v$.usesTotp.$invalid" class="has-validation">
              <span class="is-invalid"></span>
              <span class="invalid-feedback">{{ v$.usesTotp?.$errors?.[0]?.$message }}</span>
            </div>

            <smart-input
              v-if="usesTotp === false"
              id="mobileNumber"
              v-model="mobileNumber"
              class="mt-2"
              label="Mobile Number - you will occasionally receive an SMS to login"
              :is-required="!!v$.mobileNumber.isRequired"
              :validator="v$.mobileNumber"
            />

            <div v-if="!codeOnly" class="form-group has-validation mt-2">
              <label for="password" class="form-label">Create Password *</label>
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                class="form-control form-control-lg"
                :class="v$.password.$error ? 'is-invalid' : ''"
                autocomplete="new-password"
              />
              <div v-if="v$.password.$error" class="invalid-feedback">
                {{ v$.password.$errors[0].$message }}
              </div>
            </div>

            <div v-if="!codeOnly" class="form-group has-validation">
              <label for="confirmPassword" class="form-label">Confirm Password *</label>
              <input
                id="confirmPassword"
                v-model="confirmPassword"
                name="confirmPassword"
                type="password"
                class="form-control form-control-lg"
                :class="v$.confirmPassword.$error ? 'is-invalid' : ''"
                autocomplete="new-password"
              />
              <div v-if="v$.confirmPassword.$error" class="invalid-feedback">
                {{ v$.confirmPassword.$errors[0].$message }}
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div v-if="!codeOnly" class="mt-3">
                  <a href="#" @click="haveVerificationCode">I already have a verification code.</a>
                </div>
                <div v-if="codeOnly" class="mt-3">
                  <a href="#" @click="noVerificationCode">I don't have a verification code!</a>
                </div>
                <div class="mt-3">
                  <router-link to="/login">Back to Login</router-link>
                </div>
              </div>
              <div class="col">
                <div class="form-group justify-content-md-end mt-4">
                  <smart-submit-large label="Continue" :loading="loading" @click="step1" />
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>

      <div v-if="step === 2">
        <form ref="formVerificationCode" class="has-validation">
          <fieldset :disabled="loading">
            <div class="mb-5 alert alert-info">
              <small v-if="!usesTotp">We have emailed you a verification code and sent you an SMS.</small>
              <small v-else>We have emailed you a verification code.</small>
              <p>
                Enter those codes below to confirm they are correct and press validate to complete
                your registration.
              </p>
            </div>

            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
                <router-link v-if="showForgottenPassword" to="/resetpassword"
                  >Forgotten Password?
                </router-link>
              </div>
            </div>

            <div class="form-group">
              <label for="email" class="form-label"
                >Email Verification Code - sent to your email account *</label
              >
              <input
                id="verificationCode"
                v-model="verificationCode"
                name="verificationCode"
                type="text"
                class="form-control form-control-lg"
                :class="v$.verificationCode.$error ? 'is-invalid' : ''"
              />
              <div v-if="v$.verificationCode.$error" class="invalid-feedback">
                {{ v$.verificationCode.$errors[0].$message }}
              </div>
            </div>

            <smart-input
              v-if="!usesTotp"
              id="mobileVerification"
              v-model="mobileVerification"
              label="Mobile Verification Code - sent as an SMS to your mobile phone"
              :is-required="!!v$.mobileVerification.isRequired"
              :validator="v$.mobileVerification"
              custom-class="form-control-lg"
            />

            <div v-if="usesTotp">
              <div class="d-flex justify-content-center mt-4">
                <qrcode-vue :value="totpUrl" :size="200" level="H" />
              </div>
              <div class="mt-4">
                Please scan the QR code above with your authenticator app and enter the two consecutive codes below (the second code will appear once the first code has timed out).
              </div>
              <smart-input
                id="totpCode1"
                v-model="totpCode1"
                label="First Code"
                :is-required="!!v$.totpCode1.isRequired"
                :validator="v$.totpCode1"
                custom-class="form-control-lg"
              />
              <smart-input
                id="totpCode2"
                v-model="totpCode2"
                label="Second Code"
                :is-required="!!v$.totpCode2.isRequired"
                :validator="v$.totpCode2"
                custom-class="form-control-lg"
              />
            </div>

            <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end pt-5">
              <smart-submit-large label="Validate" :loading="loading" @click="step2" />
            </div>
          </fieldset>
        </form>
      </div>

      <div v-if="step === 3">
        <form ref="formLogin" class="has-validation">
          <fieldset :disabled="loading">
            <label>Your account is now activated.</label>

            <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end pt-5">
              <smart-submit-large label="Continue to login" :loading="loading" @click="step3" />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isPhoneNumber,
  SmartSubmitLarge,
  SmartInput,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { helpers, required, email, requiredIf } from '@vuelidate/validators';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'Register',
  components: { SmartSubmitLarge, SmartInput, QrcodeVue },
  data() {
    return {
      v$: useValidate(),
      email: '',
      mobileNumber: '',
      password: '',
      mobileVerification: '',
      confirmPassword: '',
      verificationCode: '',
      loading: false,
      message: '',
      step: 1,
      showLoginLink: false,
      codeOnly: false,
      showForgottenPassword: false,
      usesTotp: null,
      totpKey: null,
    };
  },
  validations: {
    email: {
      isRequired: helpers.withMessage('Email address is required', required),
      isEmail: helpers.withMessage('Must be a valid email address', email),
    },
    mobileNumber: {
      isRequired: helpers.withMessage('Mobile number is required', requiredIf((value, vm) => !vm.usesTotp)),
      isPhoneNumber: helpers.withMessage('Phone number must be in a valid format', isPhoneNumber),
    },
    mobileVerification: {
      isRequired: helpers.withMessage('Mobile verification code is required', (value, vm) => {
        return vm.step !== 2 || vm.usesTotp || (value && value.length);
      }),
    },
    confirmPassword: {
      isRequired: helpers.withMessage('Confirm password is required', (value, vm) => {
        return vm.codeOnly || (value && value.length > 1);
      }),
      isSame: helpers.withMessage('Passwords must match', (value, vm) => {
        return value === vm.password;
      }),
    },
    password: {
      isRequired: helpers.withMessage('Password is required', (value, vm) => {
        return vm.codeOnly || (value && value.length > 1);
      }),
      isGoodPassword: helpers.withMessage(
        'Password strength is not good enough',
        (password, vm) =>
          (password.length >= 8 &&
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password)) ||
          vm.codeOnly
      ),
    },
    verificationCode: {
      isRequired: helpers.withMessage('Verification code is required', (value, vm) => {
        return vm.vstep !== 2 || (value && value.length > 1 && vm.vstep === 2);
      }),
    },
    totpCode1: {
      isRequired: helpers.withMessage('First code is required', (value, vm) => {
        return vm.vstep !== 2 || !vm.usesTotp || (value && value.length > 1 && vm.vstep === 2);
      }),
    },
    totpCode2: {
      isRequired: helpers.withMessage('Second code is required', (value, vm) => {
        return vm.vstep !== 2 || !vm.usesTotp || (value && value.length > 1 && vm.vstep === 2);
      }),
    },
    usesTotp: {
      required: helpers.withMessage('2FA method is required', required),
    },
  },
  computed: {
    totpUrl() {
      return `otpauth://totp/TrustMark%20Scheme%20Portal:${encodeURIComponent(this.email)}?secret=${
            this.totpKey
        }&issuer=TrustMark&algorithm=SHA1`;
    },
  },
  methods: {
    isPhoneNumber,
    step1() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter the email address used to invite you and choose a password.';
        this.$refs.formRegister.focus();
        window.scrollTo(0, 0);
        return;
      }

      if (this.codeOnly && !this.usesTotp) {
        this.step = 2;
        this.loading = false;
        this.message = '';
        return;
      }

      this.loading = true;
      this.showLoginLink = false;
      this.message = '';

      const user = {
        email: this.email,
        password: this.password,
        mobileNumber: this.mobileNumber,
        usesTotp: this.usesTotp,
      };
      this.$store.dispatch(this.codeOnly && this.usesTotp ? 'auth/registerWithTotp' : 'auth/register', user).then(
        (resp) => {
          this.totpKey = resp.totpKey;
          window.scrollTo(0, 0);
          this.step = 2;
          this.loading = false;
          this.v$.$reset();
        },
        (error) => {
          if (error === 'Email Has not been invited') {
            error = 'The email address has not been invited, please see your scheme administrator.';
            this.email = '';
            this.password = '';
            this.confirmPassword = '';
            this.v$.$reset();
          }
          if (error.indexOf('unverified') > -1) {
            window.scrollTo(0, 0);
            this.step = 2;
          }
          if (error.indexOf('login to continue') > -1) {
            this.showLoginLink = true;
          }
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    step2() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter the verification code received through your email.';
        this.$refs.formVerificationCode.focus();
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      this.message = '';

      const user = {
        email: this.email,
        confirmationCode: this.verificationCode,
        mobileCode: this.mobileVerification,
        totpCode1: this.totpCode1,
        totpCode2: this.totpCode2,        
      };
      this.$store.dispatch(this.usesTotp ? 'auth/verifyTotpRegistration' : 'auth/verify', user).then(
        () => {
          window.scrollTo(0, 0);
          this.step = 3;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          if (
            (error.message && error.message.indexOf('forgotten your password') > -1) ||
            (error.indexOf && error.indexOf('forgotten your password')) > -1
          ) {
            this.showForgottenPassword = true;
          } else if (
            (error.message && error.message.indexOf('The email has not be registered') > -1) ||
            (error.indexOf && error.indexOf('The email has not be registered')) > -1
          ) {
            this.step = 1;
            this.message = error;
          } else {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        }
      );
    },
    step3() {
      this.$router.push('/login');
    },
    haveVerificationCode() {
      this.codeOnly = true;
      if (this.email === '') {
        this.message = 'Please enter the email address used to invite you.';
        this.$refs.emailInput.focus();
        return;
      }
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter the email address used to invite you.';
        this.$refs.emailInput.focus();
        window.scrollTo(0, 0);
      }
    },
    noVerificationCode() {
      this.codeOnly = false;
    },
  },
};
</script>

<style scoped>
.cursor-pointer.selected {
    border: 1px solid var(--bs-primary);
}

.cursor-pointer {
    cursor: pointer;
    border-radius: var(--bs-border-radius);
    border: 1px solid var(--bs-border-color);
}
</style>