import { HTTP, authHeader } from './http-service';

class WebhookService {
  update(payload) {
    return HTTP.put(`/schemewebhook`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  get() {
    return HTTP.get(`/schemewebhook`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new WebhookService();
