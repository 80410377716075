<template>
  <div v-if="!currentAccount" class="row justify-content-center p-3 m-5">
    <div class="col-8 col-md-3">
      <img src="../assets/logo.png" class="img img-fluid" />
    </div>
  </div>

  <div v-if="!currentAccount" class="container-login">
    <h2 class="pb-5">Forgot Password</h2>

    <form ref="formLogin" class="has-validation">
      <fieldset :disabled="loading">
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>

        <div v-if="!resetRequested" class="form-group">
          <label for="email" class="form-label">Your Email Address</label>
          <input
            id="email"
            v-model="email"
            name="email"
            type="text"
            class="form-control form-control-lg"
            :class="v$.email.$error ? 'is-invalid' : ''"
            autocomplete="email"
          />
          <div v-if="v$.email.$error" class="invalid-feedback">
            {{ v$.email.$errors[0].$message }}
          </div>
        </div>
        <div class="row pt-5">
          <div class="col">
            <div>
              <router-link to="/register">Register from an invite</router-link>
              <br />
            </div>
            <div class="mt-3">
              <router-link to="/login">Back to Login</router-link>
            </div>
          </div>
          <div class="col justify-content-md-end">
            <smart-submit-large
              v-if="!resetRequested"
              label="Recover Password"
              :loading="loading"
              @click="resetPassword"
            />
            <smart-submit-large v-else label="Sent" />
          </div>
        </div>

        <div class="form-group d-grid gap-2 d-md-flex"></div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { helpers, required, email } from '@vuelidate/validators';
import { SmartSubmitLarge } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'Forgot',
  components: {
    SmartSubmitLarge,
  },
  data() {
    return {
      v$: useValidate(),
      email: '',
      loading: false,
      resetRequested: false,
      message: '',
    };
  },
  validations: {
    email: {
      isRequired: helpers.withMessage('Email address is required', required),
      isEmail: helpers.withMessage('Must be a valid email address', email),
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    resetPassword() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter your email address in a valid format.';
        this.$refs.formLogin.focus();
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      this.message = '';

      this.$store.dispatch('passwordReset/sendCode', { username: this.email }).then(
        () => {
          window.scrollTo(0, 0);
          this.$router.push('/reset-password');
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style>
.container-login {
  max-width: 740px !important;
  padding: 40px 40px;
  margin: auto;
}

label {
  margin-top: 10px;
}
</style>
