import { createStore } from 'vuex';
import { addressSearch } from './address-search.module';
import { auth } from './auth.module';
import { business } from './business.module';
import { businessContact } from './business-contact.module';
import { businessTradeRegistration } from './business-trade-registration.module';
import { user } from './user.module';
import { apikey } from './apikey.module';
import { scheme } from './scheme.module';
import { webhook } from './webhook.module';
import { webhookLog } from './webhook-log.module';
import { dashboard } from './dashboard.module';
import { registrationBilling } from './registration-billing.module';
import { activityLog } from './activity-log.module';
import { passwordReset } from './password-reset.module';
import { schemePlatformStatistics } from './scheme-platform-statistics.module';
import { retrofitExports } from './export.module';
import { comms } from './comms.module';

const store = createStore({
  modules: {
    addressSearch,
    auth,
    business,
    businessContact,
    businessTradeRegistration,
    user,
    apikey,
    scheme,
    webhook,
    webhookLog,
    dashboard,
    registrationBilling,
    activityLog,
    passwordReset,
    schemePlatformStatistics,
    retrofitExports,
    comms,
  },
  state: {
    sidebarIsCollapsed: true,
    isBusy: false,
  },
  mutations: {
    setIsBusy(state, newState) {
      state.isBusy = newState;
    },
    setSidebarCollapsed(state, collapsed) {
      state.sidebarIsCollapsed = collapsed;
    },
  },
});

export default store;
