import { HTTP, authHeader } from './http-service';

class UserService {
  list() {
    return HTTP.get(`/user`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  get(payload) {
    return HTTP.post(`/user`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/user`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  invite(payload) {
    return HTTP.post(`/user/invite`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  resendInvite(payload) {
    return HTTP.post(`/user/sendInvite`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  disable(payload) {
    return HTTP.put(`/user/disable`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  enable(payload) {
    return HTTP.put(`/user/enable`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new UserService();
