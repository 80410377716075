import { authHeader, HTTP } from './http-service';

class AddressSearchService {
  get(postcode) {
    return HTTP.get(`/AddressLookup/${postcode}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new AddressSearchService();
