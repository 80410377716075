import { createWebHistory, createRouter } from 'vue-router';
import logoutCleanup from './services/logout-cleanup';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import RequestResetCode from './components/RequestResetCode.vue';
import ResetPassword from './components/ResetPassword.vue';
import store from './store';

// lazy-loaded
const Dashboard = () => import('./components/dashboard/Dashboard.vue');
const Profile = () => import('./components/Profile.vue');
const BusinessList = () => import('./components/business/BusinessList.vue');
const BusinessRegister = () => import('./components/business/BusinessRegister.vue');
const BusinessView = () => import('./components/business/BusinessView.vue');
const BusinessActivityLogView = () => import('./components/business/BusinessActivityLogView.vue');
const BusinessEdit = () => import('./components/business/BusinessEdit.vue');
const BusinessEditOverview = () => import('./components/business/BusinessEditOverview.vue');
const BusinessEditDetails = () => import('./components/business/BusinessEditDetails.vue');
const BusinessEditContacts = () => import('./components/business/BusinessEditContacts.vue');
const BusinessEditRegistration = () => import('./components/business/BusinessEditRegistration.vue');
const BusinessEditTrades = () =>
  import('./components/business/BusinessTradeRegistrations/BusinessEditTrades.vue');
const BusinessEditContactList = () => import('./components/business/BusinessEditContactList.vue');
const BusinessEditContactEdit = () => import('./components/business/BusinessEditContactEdit.vue');
const BusinessEditContactCreate = () =>
  import('./components/business/BusinessEditContactCreate.vue');

const RegistrationHistory = () => import('./components/registrations/RegistrationHistory.vue');

const UserList = () => import('./components/user/UserList.vue');
const UserInvite = () => import('./components/user/UserInvite.vue');
const UserEdit = () => import('./components/user/UserEdit.vue');
const UserView = () => import('./components/user/UserView.vue');

const SchemeInformation = () => import('./components/scheme/SchemeInformation.vue');
const SchemeTrades = () => import('./components/scheme/SchemeTrades.vue');

const ConfigureApi = () => import('./components/apiconfig/ConfigureApi.vue');

const DocumentationRouter = () => import('./components/documentation/DocumentationRouter.vue');
const DocumentationIndex = () => import('./components/documentation/DocumentationIndex.vue');
const DocumentationApiKeys = () => import('./components/documentation/DocumentationApiKeys.vue');
const DocumentationWebhooks = () => import('./components/documentation/DocumentationWebHooks.vue');

const SchemeInsights = () => import('./components/insights/SchemeInsights.vue');
const Export = () => import('./components/export/Export.vue');
const ExportList = () => import('./components/export/ExportList.vue');
const ExportNew = () => import('./components/export/ExportNew.vue');

const RetrofitAPI = () => import('./components/retrofitapi/RetrofitAPI.vue');
const RetrofitAPISettings = () => import('./components/retrofitapi/RetrofitAPISettings.vue');

const CommsLink = () => import('./components/commslink/Commslink.vue');

const ApplicationList = () => import('./components/applications/ApplicationList.vue');

const performLogoutWithLoginRedirect = () => {
  logoutCleanup();
};
const AccountView = () => import('./components/account/AccountView.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      title: 'Home',
      dashboard: true,
    },
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/register',
    component: Register,
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/forgot',
    component: RequestResetCode,
    meta: {
      title: 'Forgot',
    },
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      title: 'Reset',
    },
  },
  {
    path: '/account',
    component: AccountView,
    meta: {
      title: 'Account',
      account: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: performLogoutWithLoginRedirect,
  },
  {
    path: '/comms-link',
    component: CommsLink,
    meta: {
      title: 'Redirecting...',
      noWorkspaceLayout: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile, // lazy-loaded
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/applications',
    name: 'applications',
    component: ApplicationList, // lazy-loaded
    meta: {
      title: 'Applications',
      applications: true,
    },
  },
  {
    path: '/businesses',
    name: 'businesses',
    component: BusinessList, // lazy-loaded
    meta: {
      title: 'Businesses',
      business: true,
    },
  },
  {
    path: '/trades',
    name: 'trades',
    component: SchemeTrades, // lazy-loaded
    meta: {
      title: 'Scheme Trades',
      trades: true,
    },
  },
  {
    path: '/insights',
    name: 'insights',
    component: SchemeInsights, // lazy-loaded
    meta: {
      title: 'Scheme Insights',
      insights: true,
    },
  },
  {
    path: '/export',
    name: 'export',
    component: Export, // lazy-loaded
    meta: {
      title: 'Export',
      exports: true,
    },
    children: [
      {
        path: '',
        component: ExportList, // lazy-loaded
        meta: {
          exports: true,
          list: true,
        },
      },
      {
        path: 'new',
        component: ExportNew, // lazy-loaded
        meta: {
          exports: true,
          new: true,
        },
      },
    ],
  },
  {
    path: '/retrofitapi',
    name: 'retrofitapi',
    component: RetrofitAPI, // lazy-loaded
    meta: {
      title: 'RetrofitAPI',
      retrofitapi: true,
    },
    children: [
      {
        path: '',
        component: RetrofitAPISettings, // lazy-loaded
        meta: {
          settings: true,
        },
      },
    ],
  },
  {
    path: '/business/register',
    name: 'businesses-register',
    component: BusinessRegister, // lazy-loaded
    meta: {
      title: 'Register Businesses',
      business: true,
    },
  },
  {
    path: '/business/:businessId',
    name: 'businesses-view',
    component: BusinessView, // lazy-loaded
    meta: {
      title: 'View Business',
      business: true,
    },
  },
  {
    path: '/business/:businessId/activity-log',
    name: 'businesses-activity-log-view',
    component: BusinessActivityLogView, // lazy-loaded
    meta: {
      title: 'View Business Activity Log',
      business: true,
    },
  },
  {
    path: '/business/:businessId/edit',
    name: 'businesses-edit',
    component: BusinessEdit, // lazy-loaded
    meta: {
      title: 'Edit Business',
      business: true,
    },
    children: [
      {
        path: '',
        component: BusinessEditDetails, // lazy-loaded
        meta: {
          details: true,
          displayActionButtons: true,
          displayNextButton: true,
          saveOnNextButton: true,
          nextButtonPath: '/business/:businessId/edit/registration',
        },
      },
      {
        path: 'actions',
        component: BusinessEditOverview, // lazy-loaded
      },
      {
        path: 'details',
        component: BusinessEditDetails, // lazy-loaded
        meta: {
          details: true,
          displayActionButtons: true,
          displayNextButton: true,
          saveOnNextButton: true,
          nextButtonPath: '/business/:businessId/edit/registration',
        },
      },
      {
        path: 'contacts',
        component: BusinessEditContacts, // lazy-loaded
        children: [
          {
            path: '',
            component: BusinessEditContactList, // lazy-loaded
          },
          {
            path: 'create',
            component: BusinessEditContactCreate, // lazy-loaded
          },
          {
            path: 'edit/:contactId',
            component: BusinessEditContactEdit, // lazy-loaded
          },
        ],
      },
      {
        path: 'registration',
        component: BusinessEditRegistration, // lazy-loaded
        meta: {
          displayActionButtons: true,
          displayNextButton: true,
          nextButtonPath: '/business/:businessId/edit/trades',
        },
      },
      {
        path: 'trades',
        component: BusinessEditTrades, // lazy-loaded
        meta: {
          displayActionButtons: true,
          displayNextButton: true,
          nextButtonPath: '/business/:businessId/edit/actions',
        },
      },
    ],
  },
  {
    path: '/application/:businessId',
    name: 'applications-view',
    component: BusinessView, // lazy-loaded
    meta: {
      title: 'View Business',
      applications: true,
    },
  },
  {
    path: '/application/:businessId/activity-log',
    name: 'applications-activity-log-view',
    component: BusinessActivityLogView, // lazy-loaded
    meta: {
      title: 'View Business Activity Log',
      applications: true,
    },
  },
  {
    path: '/application/:businessId/edit',
    name: 'applications-edit',
    component: BusinessEdit, // lazy-loaded
    meta: {
      title: 'Edit Business',
      applications: true,
    },
    children: [
      {
        path: '',
        component: BusinessEditDetails, // lazy-loaded
        meta: {
          details: true,
          displayActionButtons: true,
          displayNextButton: true,
          saveOnNextButton: true,
          nextButtonPath: '/application/:businessId/edit/registration',
        },
      },
      {
        path: 'actions',
        component: BusinessEditOverview, // lazy-loaded
      },
      {
        path: 'details',
        component: BusinessEditDetails, // lazy-loaded
        meta: {
          details: true,
          displayActionButtons: true,
          displayNextButton: true,
          saveOnNextButton: true,
          nextButtonPath: '/application/:businessId/edit/registration',
        },
      },
      {
        path: 'contacts',
        component: BusinessEditContacts, // lazy-loaded
        children: [
          {
            path: '',
            component: BusinessEditContactList, // lazy-loaded
          },
          {
            path: 'create',
            component: BusinessEditContactCreate, // lazy-loaded
          },
          {
            path: 'edit/:contactId',
            component: BusinessEditContactEdit, // lazy-loaded
          },
        ],
      },
      {
        path: 'registration',
        component: BusinessEditRegistration, // lazy-loaded
        meta: {
          displayActionButtons: true,
          displayNextButton: true,
          nextButtonPath: '/application/:businessId/edit/trades',
        },
      },
      {
        path: 'trades',
        component: BusinessEditTrades, // lazy-loaded
        meta: {
          displayActionButtons: true,
          displayNextButton: true,
          nextButtonPath: '/application/:businessId/edit/actions',
        },
      },
    ],
  },
  {
    path: '/registrations',
    name: 'registrations',
    component: RegistrationHistory, // lazy-loaded
    meta: {
      title: 'Registration History',
      registrations: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: UserList, // lazy-loaded
    meta: {
      title: 'Users',
      users: true,
    },
  },
  {
    path: '/users/invite',
    name: 'userinvite',
    component: UserInvite, // lazy-loaded
    meta: {
      title: 'Invite User',
      users: true,
    },
  },
  {
    path: '/user/:userId/edit',
    name: 'userEdit',
    component: UserEdit, // lazy-loaded
    meta: {
      title: 'Edit User',
      users: true,
    },
  },
  {
    path: '/user/:userId',
    name: 'userView',
    component: UserView, // lazy-loaded
    meta: {
      title: 'View User',
      users: true,
    },
  },
  {
    path: '/scheme',
    name: 'scheme',
    component: SchemeInformation, // lazy-loaded
    meta: {
      title: 'Scheme',
      scheme: true,
    },
  },
  {
    path: '/api',
    name: 'api',
    component: ConfigureApi, // lazy-loaded
    meta: {
      title: 'Configure API',
      api: true,
    },
  },
  {
    path: '/docs',
    name: 'documentation',
    component: DocumentationRouter, // lazy-loaded
    meta: {
      title: 'Documentation',
      documentation: true,
    },
    children: [
      {
        path: '',
        component: DocumentationIndex, // lazy-loaded
      },
      {
        path: 'webhooks',
        component: DocumentationWebhooks, // lazy-loaded
      },
      {
        path: 'apikeys',
        component: DocumentationApiKeys, // lazy-loaded
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot', '/reset-password'];
  const authRequired = !publicPages.includes(to.path);
  const isPublicPage = publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('account');
  // Collapse menu
  store.commit('setSidebarCollapsed', true);

  // Redirect to login if not logged in and attempting to access route that requires permission
  if (authRequired && !loggedIn) {
    next('/login');
  } else if (isPublicPage && loggedIn) {
    localStorage.removeItem('account');
    window.location = to.path;
  } else if (!store.state.auth.appData && loggedIn) {
    store.dispatch('auth/getAppData').then(() => {
      next();
    });
  } else {
    next();
  }
});

export default router;
