import { HTTP, authHeader } from './http-service';

class WebhookLogService {
  get() {
    return HTTP.get(`/WebHookLog/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new WebhookLogService();
