<template>
  <div id="sidebarMenu" class="bg-light shift" :class="{ expanded: !isCollapsed }">
    <nav class="nav-container">
      <div class="position-sticky w-100 pt-3">
        <scheme-logo
          v-if="schemeLogoImageKey"
          style="width: 100%"
          class="col-sm-3"
          :imagekey="schemeLogoImageKey"
        />
        <ul class="nav flex-column flex-nowrap">
          <li class="nav-item">
            <router-link
              v-if="isSchemeBusinessViewer"
              to="/"
              class="nav-link"
              :class="selectedDashboard"
            >
              <font-awesome-icon icon="chart-line" class="icon" /> Dashboard</router-link
            >
          </li>
          <li v-if="isSchemeBusinessViewer" class="nav-item">
            <router-link to="/applications" class="nav-link" :class="selectedApplications"
              ><font-awesome-icon icon="calendar-check" class="icon" /> Applications
              <span class="rounded-pill badge bg-danger ms-2" style="vertical-align: text-top">
                {{ applicationCount }}
                <span class="visually-hidden">applications</span>
              </span></router-link
            >
          </li>
          <li v-if="isSchemeBusinessViewer" class="nav-item">
            <router-link to="/businesses" class="nav-link" :class="selectedBusinesses"
              ><font-awesome-icon icon="users" class="icon" /> Businesses</router-link
            >
          </li>
          <li v-if="isBillingAdmin" class="nav-item">
            <router-link to="/registrations" class="nav-link" :class="selectedRegistrations"
              ><font-awesome-icon icon="calendar-check" class="icon" /> Registration
              History</router-link
            >
          </li>
          <li v-if="hasSchemePortalAccess" class="nav-item">
            <router-link to="/scheme" class="nav-link" :class="selectedScheme"
              ><font-awesome-icon icon="circle-question" class="icon" /> Scheme Information</router-link
            >
          </li>
          <li v-if="hasSchemePortalAccess" class="nav-item">
            <router-link to="/trades" class="nav-link" :class="selectedTrades"
              ><font-awesome-icon icon="check-double" class="icon" /> Scheme Trades</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/insights" class="nav-link" :class="selectedInsights"
              ><font-awesome-icon icon="chart-bar" class="icon" /> Scheme Insights</router-link
            >
          </li>
          <li v-if="hasExportRequestAccess" class="nav-item">
            <router-link to="/export" class="nav-link" :class="selectedExports"
              ><font-awesome-icon icon="download" class="icon" /> Exports</router-link
            >
          </li>
          <li v-if="hasSchemeRIAPIManagerAccess" class="nav-item">
            <router-link to="/retrofitapi" class="nav-link" :class="selectedRetrofitAPI"
              ><font-awesome-icon icon="chart-bar" class="icon" /> Retrofit API</router-link
            >
          </li>
          <li v-if="isUserAdmin" class="nav-item">
            <router-link to="/users" class="nav-link" :class="selectedUsers"
              ><font-awesome-icon icon="address-card" class="icon" /> Users</router-link
            >
          </li>
          <li v-if="isApiAdmin" class="nav-item">
            <router-link to="/api" class="nav-link" :class="selectedApi"
              ><font-awesome-icon icon="laptop-code" class="icon" /> API</router-link
            >
          </li>
          <li v-if="isApiAdmin" class="nav-item">
            <router-link to="/docs" class="nav-link" :class="selectedDocumentation"
              ><font-awesome-icon icon="book-open" class="icon" /> Documentation</router-link
            >
          </li>
        </ul>

        <hr />
        <h6
          class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
        >
          <span>Quick Links</span>
        </h6>
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://www.trustmark.org.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustMark
            </a>
          </li>
          <li class="nav-item">
            <router-link to="/comms-link" class="nav-link" rel="noopener noreferrer"
              >Comms Platform
              <span class="rounded-pill badge bg-danger ms-2" style="vertical-align: text-top">
                {{ unreadMessageCount }}
                <span class="visually-hidden">unread messages</span>
              </span>
            </router-link>
          </li>
        </ul>
        <hr />
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <router-link to="/account" class="nav-link" :class="selectedAccount"><font-awesome-icon
                icon="user" class="icon" /> Account</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { SchemeLogo } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemeSidebar',
  components: {
    SchemeLogo,
  },
  data() {
    return {
      applicationCount: 0,
    };
  },
  computed: {
    unreadMessageCount() {
      return this.$store.state.comms.unreadMessageCount;
    },
    isCollapsed() {
      return this.$store.state.sidebarIsCollapsed;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    schemeLogoImageKey() {
      if (this.$store.state.auth.account.scheme === null) {
        return null;
      }
      return this.$store.state.auth.account.scheme.logoSideBarUrl;
    },
    hasSchemePortalAccess() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemePortalAccess');
      }
      return false;
    },
    isUserAdmin() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemePortalUserViewer');
      }
      return false;
    },
    isApiAdmin() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemeAPIKeyManager');
      }
      return false;
    },
    isSchemeBusinessViewer() {
      if (this.currentAccount && this.currentAccount.roles) {
        return (
          this.currentAccount.roles.includes('SchemeBusinessViewer') ||
          this.currentAccount.roles.includes('SchemeBusinessEditor')
        );
      }
      return false;
    },
    isBusinessEditor() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemeBusinessEditor');
      }
      return false;
    },
    isBillingAdmin() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemeBillingManager');
      }
      return false;
    },
    hasBackOfficePortalAccess() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('BackOfficePortalAccess');
      }
      return false;
    },
    hasExportRequestAccess() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('RetrofitExportRequest');
      }
      return false;
    },
    hasSchemeRIAPIManagerAccess() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemeRIAPIManager');
      }
      return false;
    },
    selectedDashboard() {
      return this.$route.meta.dashboard ? 'selected' : '';
    },
    selectedBusinesses() {
      return this.$route.meta.business ? 'selected' : '';
    },
    selectedApplications() {
      return this.$route.meta.applications ? 'selected' : '';
    },
    selectedRegistrations() {
      return this.$route.meta.registrations ? 'selected' : '';
    },
    selectedScheme() {
      return this.$route.meta.scheme ? 'selected' : '';
    },
    selectedTrades() {
      return this.$route.meta.trades ? 'selected' : '';
    },
    selectedUsers() {
      return this.$route.meta.users ? 'selected' : '';
    },
    selectedRetrofitAPI() {
      return this.$route.meta.retrofitapi ? 'selected' : '';
    },
    selectedExports() {
      return this.$route.meta.exports ? 'selected' : '';
    },
    selectedApi() {
      return this.$route.meta.api ? 'selected' : '';
    },
    selectedDocumentation() {
      return this.$route.meta.documentation ? 'selected' : '';
    },
    selectedInsights() {
      return this.$route.meta.insights ? 'selected' : '';
    },
    selectedAccount() {
      return this.$route.meta.account ? 'selected' : '';
    },
  },
  watch: {
    isCollapsed(collapsed) {
      (collapsed ? this.removeListeners : this.addListeners)();
    },
  },
  mounted() {
    setInterval(() => {
      this.$store.dispatch('comms/getCommsUnreadMessageCount').catch(() => {}); // ignore errors
    }, 1000 * 60 * 5); // 5 minutes
    this.$store.dispatch('comms/getCommsUnreadMessageCount').catch(() => {});
    this.$store
      .dispatch('business/searchBusinessApplication', {
        pageParameters: {
          size: 10,
          number: 0,
        },
        sort: {
          item: 'createdAt',
          direction: 'descending',
        },
        fieldNames: [],
        status: 2,
        operator: 1,
        filterType: 0,
      })
      .then((results) => {
        this.applicationCount = results.totalItems;
        this.$store.commit('business/setApplicationSearchPageNumber', 0, { root: true });
      })
      .catch(() => {});
  },
  methods: {
    addListeners() {
      window.addEventListener('click', this.onClickOutside, { passive: true, capture: true });
    },
    removeListeners() {
      window.removeEventListener('click', this.onClickOutside, { passive: true, capture: true });
    },
    onClickOutside(event) {
      const el = this.$el;
      if (el !== event.target && !event.composedPath().includes(el)) {
        event.stopPropagation();
        this.$store.commit('setSidebarCollapsed', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sidebarMenu {
  background-color: #71217b;
  padding: 10px 0;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
  display: none;
  width: 100%;
  padding-top: 0;
  &.expanded {
    display: flex;
  }
}

@include media-breakpoint-up(lg) {
  #sidebarMenu {
    display: flex;
    width: 226px;
    // min-height: calc(100vh - 62px);
  }

  .nav-container {
    position: fixed;
    width: 226px;
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
    box-sizing: border-box;
    margin-right: 20px;
  }
}

.nav-link {
  font-size: 0.875em;
  font-weight: 600;
  color: #495057;
  white-space: nowrap;
  padding: 10px 10px;
}

.nav-item .icon {
  font-size: $font-size-base;
  margin-right: 2px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
}

/* SHIFT */
.shift ul li {
  position: relative;
  z-index: 1;
}

.shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: '.';
  color: transparent;
  background: #dee2e6;
  visibility: none;
  opacity: 0;
  z-index: -1;
  border-radius: 5px;
}

.selected {
  background: #71217b;
  border-radius: 5px;
  color: white !important;
}

.shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.shift ul li a:hover {
  color: #000;
}
</style>
