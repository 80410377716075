import { HTTP, authHeader } from './http-service';

class DashboardService {
  get() {
    return HTTP.get(`/dashboard`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new DashboardService();
