import { HTTP, authHeader } from './http-service';

class CommsService {
  getCommsLink() {
    return HTTP.post(`/commsplatform/comms`, {}, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  getCommsUnreadMessageCount() {
    return HTTP.post(`/commsplatform/unreadmessagecount`, {}, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }
}

export default new CommsService();
