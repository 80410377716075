<template>
  <div v-show="isBusy" id="" class="h-100" :class="{ loading: isBusy }">
    <div class="loading-dots">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusySpinner',
  props: {
    manualBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isBusy() {
      return this.$store.state.isBusy || this.manualBusy;
    },
  },
};
</script>

<style lang="scss">
@import '../scss/loading';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
}

.spinner {
  height: 150px;
  margin: 0 auto;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
