import WebhookLogService from '../services/webhook-log-service';

export const webhookLog = {
  namespaced: true,
  state: {
    logs: null,
  },
  actions: {
    get({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setLogs', null);
      return WebhookLogService.get().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setLogs', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setLogs(state, logs) {
      state.logs = logs;
    },
  },
};
