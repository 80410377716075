import CommsService from '../services/comms-service';

export const comms = {
  namespaced: true,
  state: {
    unreadMessageCount: 0,
  },
  actions: {
    getCommsLink({ commit }) {
      commit('setIsBusy', true, { root: true });
      return CommsService.getCommsLink().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.url);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getCommsUnreadMessageCount({ commit }) {
      commit('setIsBusy', true, { root: true });
      return CommsService.getCommsUnreadMessageCount().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUnreadMessageCount', result.unreadMessageCount);
          return Promise.resolve(result.unreadMessageCount);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setUnreadMessageCount(state, count) {
      state.unreadMessageCount = count;
    },
  },
};
